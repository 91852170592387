import './src/styles/styles.scss';
import Popper from 'popper.js/dist/popper.js';

window.$ = require("jquery");
window.Popper = Popper;
require('bootstrap/dist/js/bootstrap.js');

window.addDataLayer = (function (value) {
    window.dataLayer = window.dataLayer || [];
    if (window.dataLayer) {
        window.dataLayer.push(value);
    } else {
        console.log("dataLayer is undefined");
    }
});

window.showBrand = true;
var scrolling = false;
var navBarTogglePos = 160;

window.$(window).scroll(function () {
    if (!scrolling) {
        scrolling = true;
        setTimeout(function () {
            scrolling = false;
            if (
                window.$(window).scrollTop() > navBarTogglePos && window.showBrand ||
                window.$(window).scrollTop() === 0 && !window.showBrand
            ) {
                handleScroll();
            }
        }, 100);
    }
});

function handleScroll() {
    if (window.$("nav.sticky-top").offset() !== undefined) {
        if ((window.$("nav.sticky-top").offset().top > navBarTogglePos && window.$(window).width() >= 992) ||
            (window.$("nav.sticky-top").offset().top > navBarTogglePos && window.$(window).width() < 992)) {
            if (window.showBrand) {
                window.$(".navbar-brand>div").fadeOut(100);
                window.$("body").addClass("header-changed");
                window.showBrand = false;
            }
        } else {
            if (!window.showBrand) {
                window.$(".navbar-brand>div").fadeIn(100);
                window.$("body").removeClass("header-changed");
                window.showBrand = true;
            }
        }
    }
}

export const onRouteUpdate = ({ location }) => {

    window.addDataLayer({
        "page_name": location.pathname,
        "page_type": 'content',
        "page_language": location.pathname.split("/")[1]
    });

    window.addDataLayer({ event: `gatsbyRouteChange` });
};
